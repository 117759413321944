.step-switch {
  $step-switch-animation-time: 400ms;

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity $step-switch-animation-time !important;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity $step-switch-animation-time !important;
  }
}

.fade-in {
  animation-duration: 400ms;
  animation-fill-mode: backwards;
  animation-name: fade-in-animation;
}

@keyframes fade-in-animation {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
