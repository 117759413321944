.notFound {
  height: 100vh;
  box-sizing: border-box;
  padding: var(--page-padding);

  .innerContainer {
    position: relative;
    overflow: hidden;
    height: 100%;
    padding: 0 !important;
    border-radius: 12px;
    background: var(--color-white);
    box-shadow: 0 44px 54px -40px rgb(0 0 0 / 25%);
    transition: background-color 800ms;
  }
}
