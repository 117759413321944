@import "./fonts/iqos-sans.scss";
@import "./fonts/noto-sans.scss";
@import "./fonts/inter.scss";
@import "./fonts/montserrat.scss";
@import "./animations.scss";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--font-main);
}

#root {
  --font-main: "IQOS Sans", "Inter", "Noto Sans", sans-serif;
  --color-darkest: #000000;
  --color-iq-tuna: #33303c;
  --color-mine-shaft: #313131;
  --color-dove-gray: #6f6f6f;
  --color-iq-silver: #cdcdcd;
  --color-bon-jour: #e1dfe1;
  --color-wild-sand: #f5f5f5;
  --color-iq-pampas: #f6f4f0;
  --color-soft-white: #fffdfb;
  --color-white: #ffffff;
  --color-iq-blue: #7b61ff;
  --color-turquoise: #00d1d2;
  --color-pomegranate: #f32a2a;
  --color-taste-yellow: #eac702;
  --color-taste-amber: #ca5e21;
  --color-taste-silver: #c9c9c9;
  --color-taste-turquoise: #03b5b6;
  --color-taste-purple: #baa5ce;
  --color-taste-noor: #c37625;
  --color-taste-apricity: #ff9052;
  --color-taste-yugen: #b95ec2;
  --color-oxford-blue: #002548;
  --page-padding: 24px;

  @media only screen and (max-width: 800px) {
    --page-padding: 12px;
  }
}

img,
source {
  display: block;
  max-width: 100%;
}
