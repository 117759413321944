@font-face {
  font-family: "IQOS Sans";
  font-weight: 400;
  src: url("../../fonts/IqosSans/IqosSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "IQOS Sans";
  font-weight: 700;
  src: url("../../fonts/IqosSans/IqosSans-Bold.ttf") format("truetype");
}
